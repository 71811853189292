import React from "react";
import style from "style/Header.module.scss";
import Nav from "./Nav";
import logo from "res/mk.svg";

class Header extends React.Component<{}, {}> {
	public render() {
		return (
			<header className={style.Header}>
				<div className={style.inside}>
					<a href="#top" aria-label="Back to top"><img src={logo} className={style.logo} alt="MK" /></a>
					<div className={style.spacer}></div>
					<Nav />
				</div>
			</header>
		);
	}
}

export default Header;
