import React from "react";
import Header from "./Header";
import About from "./About";
import MyWork from "./MyWork";
import Contact from "./Contact";
import Footer from "./Footer";

class Page extends React.Component<{}, {}> {
	public render() {
		return (
			<div>
				<Header />
				<main>
					<About />
					<MyWork />
					<Contact />
				</main>
				<Footer />
			</div>
		);
	}
}

export default Page;
