import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

interface FAProps {
	icon: IconDefinition;
	className?: string;
}

class FA extends React.Component<FAProps, {}> {
	public render() {
		const {className, ...miscProps} = this.props;
		return (<FontAwesomeIcon {...miscProps} className={`faIcon ${className || ""}`} />);
	}
}

export default FA;
