import React from "react";
import style from "style/Nav.module.scss";
import AX from "./ExternalLink";
import FA from "./FA";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";

class Nav extends React.Component<{}, {}> {
	public render() {
		return (
			<nav className={style.Nav}>
				<ul>
					<li><a href="#about">About</a></li>
					<li><a href="#myWork">My work</a></li>
					<li><a href="#contact">Contact</a></li>
					<li className={style.resumeLink}>
						<AX href={process.env.PUBLIC_URL + "/resume.pdf"}>
							Resume<FA icon={faExternalLink} />
						</AX>
					</li>
				</ul>
			</nav>
		);
	}
}

export default Nav;
