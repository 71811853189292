import React from "react";
import style from "style/MyWork.module.scss";
import Work from "./Work";
import imgCO from "res/logos/cyberoptics.png";
import imgKKA from "res/screenshots/k-kahn-art.png";
import imgRIP from "res/screenshots/rooted-in-pride.png";
import imgLinter from "res/screenshots/java-linter.png";
import imgGAS from "res/screenshots/genetic-algorithm-sim.png";

class MyWork extends React.Component<{}, {}> {
	public render() {
		return (
			<section id="myWork" className={style.MyWork}>
				<h2>My Work</h2>
				<div className={style.works}>
					<Work
						title="CyberOptics"
						position="Software Intern"
						dates="Summer 2023"
						tech={["C#", "WPF", "Visual Studio"]}
						coUrl="https://www.nordson.com/en/divisions/test-and-inspection"
						imgSrc={imgCO}
						imgMaxHeight={29}
					>
						<ul>
							<li>Developed a system test suite for the quality control of a new wireless image-processing device</li>
							<li>Designed an API and user interface so that customers can use a new feature of the device</li>
							<li>Collaborated with firmware developers to help diagnose issues in the device firmware</li>
						</ul>
					</Work>
					<Work
						isProject
						title="K. Kahn Art"
						tech={["React", "TypeScript", "CSS", "Firebase"]}
						url="https://kkahnart.com"
						imgSrc={imgKKA}
					>
						<p>The portfolio site of Kathleen Kahn, visual artist.</p>
					</Work>
					<Work
						isProject
						title="Rooted in Pride Event Guide"
						tech={["HTML", "CSS", "JS"]}
						url="https://rooted-in-pride.web.app"
						imgSrc={imgRIP}
					>
						<p>A website used by attendees of Terre Haute's first Pride Festival to view schedules and activity details. Created as a volunteer project.</p>
					</Work>
					<Work
						isProject
						title="Java Linter"
						tech={["Java"]}
						ghUrl="https://github.com/mkacct/csse375-project-2.1"
						imgSrc={imgLinter}
					>
						<p>A linter to be run on Java bytecode to detect style issues, design principle violations, and instances of design patterns. The flexible design allows the set of checks to be easily expanded. Created with Nolan Cales and Kyle Asbury as an academic project, and developed futher with Ryan Shiraki.</p>
					</Work>
					<Work
						isProject
						title="Genetic Algorithm Simulator"
						tech={["Java"]}
						ghUrl="https://github.com/mkacct/csse220-final-project"
						imgSrc={imgGAS}
					>
						<p>A customizable evolution simulator, allowing for the demonstration and analysis of several types of genetic algorithms. You can select a fitness function, selection method, crossover method, and customize other properties. Created with Ash Collins as an academic project.</p>
					</Work>
				</div>
			</section>
		);
	}
}

export default MyWork;
