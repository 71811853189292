import React from "react";

interface ExternalLinkProps {
	href: string;
	children: React.ReactNode;
}

class ExternalLink extends React.Component<ExternalLinkProps & Record<string, any>, {}> {
	public render() {
		const {href, target, rel, ...miscProps} = this.props;
		return (
			<a href={this.props.href} target="_blank" rel="noopener noreferrer" {...miscProps}>{this.props.children}</a>
		);
	}
}

export default ExternalLink;
