import React from "react";
import style from "style/About.module.scss";

class About extends React.Component<{}, {}> {
	public render() {
		return (
			<section id="about" className={style.About}>
				<h1>Madeline Kahn</h1>
				<h2>Software Engineer</h2>
				<p>I'm a software developer with a passion for user experience. I've worked with many different languages and technologies on front-end and back-end systems. Graduating Rose-Hulman Institute of Technology in May 2025; looking forward to future employment!</p>
			</section>
		);
	}
}

export default About;
