import React from "react";
import ReactDOM from "react-dom/client";
import "style/root.module.scss";
import HoverClassManager from "script/hover-class";
import Page from "components/Page";

new HoverClassManager();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<Page />
	</React.StrictMode>
);
