export default class HoverClassManager {
	private lastTouched: Date;

	public constructor() {
		this.lastTouched = new Date(0);
		document.addEventListener("touchstart", () => {
			this.lastTouched = new Date();
			document.body.classList.remove("canHover");
		}, true);
		document.addEventListener("mousemove", () => {
			if ((new Date()).getTime() - this.lastTouched.getTime() < 500) {return;}
			document.body.classList.add("canHover");
		}, true);
	}
}
