import React from "react";
import style from "style/Contact.module.scss";
import AX from "./ExternalLink";
import FA from "./FA";
import {faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons";

class Contact extends React.Component<{}, {}> {
	public render() {
		return (
			<section id="contact" className={style.Contact}>
				<h2>Contact</h2>
				<address>
					<p>
						For business inquiries: <a href="mailto:kahnmv@rose-hulman.edu">kahnmv@rose-hulman.edu</a><br />
						For other communication: <a href="mailto:mk-acct@outlook.com">mk-acct@outlook.com</a>
					</p>
					<p className={style.socials}>
						<AX href="https://www.linkedin.com/in/m-kahn" title="LinkedIn"><FA icon={faLinkedin} /></AX>
						<AX href="https://github.com/mkacct" title="GitHub"><FA icon={faGithub} /></AX>
					</p>
				</address>
			</section>
		);
	}
}

export default Contact;
