import React from "react";
import style from "style/Footer.module.scss";

class Footer extends React.Component<{}, {}> {
	public render() {
		return (
			<footer className={style.Footer}>
				<div className={style.inside}>
					<p className={style.kindaHeader}>mkps.app</p>
					<p className={style.tech}>React · TypeScript · Sass</p>
					<p className={style.copyright}>© 2024 Madeline Kahn</p>
				</div>
			</footer>
		);
	}
}

export default Footer;
