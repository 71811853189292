import React from "react";
import style from "style/Work.module.scss";
import AX from "./ExternalLink";
import FA from "./FA";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {faGithub} from "@fortawesome/free-brands-svg-icons";

interface WorkProps {
	isProject?: boolean;
	title: string;
	position?: string;
	dates?: string;
	tech: string[];
	url?: string;
	coUrl?: string;
	ghUrl?: string;
	imgSrc: string;
	imgMaxHeight?: number;
	children: React.ReactNode;
}

class Work extends React.Component<WorkProps, {}> {
	public render() {
		return (
			<section className={`${style.Work} ${this.props.isProject ? style.project : style.job}`}>
				<img src={this.props.imgSrc} alt={this.props.title} style={{maxHeight: this.props.imgMaxHeight}} />
				<div className={style.content}>
					<div className={style.headings}>
						<h3>{this.props.title}</h3>
						{this.props.isProject ? null : (<h4>{`${this.props.position} · ${this.props.dates}`}</h4>)}
					</div>
					<div>{this.props.children}</div>
					<p className={style.tech}>{this.props.tech.join(" · ")}</p>
					<div className={style.opts}>
						{this.props.url ? (<AX href={this.props.url}><FA icon={faExternalLink} />Open</AX>) : null}
						{this.props.coUrl ? (<AX href={this.props.coUrl}><FA icon={faExternalLink} />Company website</AX>) : null}
						{this.props.ghUrl ? (<AX href={this.props.ghUrl}><FA icon={faGithub} />GitHub</AX>) : null}
					</div>
				</div>
			</section>
		);
	}
}

export default Work;
